
    import DomainDetails from "@/components/DomainDetails.vue";
    import BackButton from "@/components/BackButton.vue";
    import getDomainFromPath from "@/util/getDomainFromPath";
    import { defineComponent, onMounted, ref } from "vue";
    import { Ref } from "@vue/reactivity";
    import { getDomain } from "@/service/domains";
    import { DomainStatusDTO } from "@/model/dto/domain_v2";

    export default defineComponent({
        name: "DomainView",
        components: {
            DomainDetails,
            BackButton,
        },
        setup() {
            const pathDomain = getDomainFromPath();
            let domain: Ref<DomainStatusDTO | null> = ref(null);
            const loadingError: Ref<boolean> = ref(false);

            const loadDomain = async () => {
                console.log("loadDomain", pathDomain.value);
                try {
                    domain.value = await getDomain(pathDomain.value);
                } catch (e: any) {
                    loadingError.value = true;
                }
            };
            onMounted(loadDomain);

            return { domain, loadingError, loadDomain };
        },
    });
