<template>
    <Button class="p-button-text" @click="navigate"><i class="pi pi-arrow-left"></i>Back</Button>
</template>

<script>
    import { defineComponent } from "vue";
    import Button from "primevue/button/Button.vue";
    import router from "@/router";

    export default defineComponent({
        components: {
            Button,
        },
        methods: {
            navigate() {
                router.back();
            },
        },
    });
</script>

<style lang="scss" scoped>
    button {
        padding: 0;

        &:hover {
            background: transparent !important;
        }
    }

    i {
        margin-right: 0.2rem;
    }
</style>
